ion-content,
ion-item {
  --background: #ffff;
  --overflow: scroll;
}

ion-header {
  ion-toolbar {
    height: 100%;
    --background: #002856;
    text-align: center;
  }
}

ion-footer {
  ion-button {
    margin: auto !important;
  }
  ion-icon {
    color: white;
  }
  ion-toolbar {
    height: 100%;
    --background: #002856;
    text-align: center;
  }
}

.btn {
  --background: white;
  color: var(--text);
  border: solid #e8e8e8 2px;
  --background-activated: var(--active-button);
  --border-radius: 5px;
}

.shadow {
  -webkit-box-shadow: 0px 0px 21px #e6e6e6;
  -moz-box-shadow: 0px 0px 21px #e6e6e6;
  box-shadow: 0px 0px 21px #e6e6e6;
  --box-shadow: 0px 14px 25px #c2c2c2;
}

.center {
  display: block;
  margin: auto;
  width: 50%;
}
